
import { ref, watch } from 'vue';
import Toolbar from 'primevue/toolbar';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useRoute, useRouter } from 'vue-router';

import { Icon, Empty, Loader, StatusBool } from '../../components/ui';
import { Paginator } from '../../components/common';
import { useStores } from '@/store/Stores';

export default {
  components: {
    Toolbar,
    InputText,
    DataTable,
    Column,
    Icon,
    Empty,
    Loader,
    StatusBool,
    Paginator
  },
  setup() {
    const { companiesStore, companiesStoreState } = useStores();

    const router = useRouter();
    const route = useRoute();

    const search = ref();

    companiesStore.init({ per_page: 15, page: route.query.page, search: search.value });

    watch(route, () => {
      companiesStore.init({ per_page: 15, page: route.query.page, search: search.value });
    });

    watch(search, (newValue) => {
      // const query = {...route.query, ...{ search: newValue }}
      router.push({ name: route.name as any, query: { search: newValue } });
    });

    return {
      state: companiesStoreState,
      search
    };
  }
};
